<template>
  <div class="recent">
    <NoteCard
      v-for="(note, index) of items"
      :key="index"
      :note="note"
      :isRecent="true"
      variant="transparent"
    />
  </div>
</template>

<script>
export default {
  name: "Recent",
  components: {
    NoteCard: () => import("@/components/Cards/NoteCard"),
  },
  props: {
    items: Array,
  },
};
</script>
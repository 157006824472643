<template>
  <div class="home">
    <b-col>
      <section class="w-100">
        <b-tabs content-class="mt-3 w-100" style="width: 100%">
          <b-tab :title="$t('notes.allNotes')">
            <Tree :items="$store.getters.notes" />
          </b-tab>
          <b-tab :title="$t('notes.recent')" active>
            <Recent :items="$store.getters.newNotes" />
          </b-tab>
        </b-tabs>
      </section>
    </b-col>
  </div>
</template>
<script>
import Tree from "@/components/Tree";
import Recent from "@/components/Recent";

export default {
  components: {
    Tree,
    Recent,
  },
};
</script>
<style>
</style>

<template>
  <div class="tree w-100">
    <Card
      v-for="(cours, index) in items"
      :key="index"
      class="mb-2 p-2 card-cours"
      v-b-toggle="`${index}-cours`"
    >
      <template v-slot:header>
        <h5>{{ cours.cours_libelle.toUpperCase() }}</h5>
        <b-icon
          icon="arrow-down-up"
          variant="text-primary"
          scale="1.25"
        ></b-icon>
      </template>
      <template>
        <b-collapse :id="`${index}-cours`" class="mt-2 collapser">
          <NoteCard
            v-for="(note, index) of cours.epreuves"
            :key="index"
            :note="note"
            :isRecent="false"
          />
        </b-collapse>
      </template>
    </Card>
  </div>
</template>

<script>
export default {
  name: "Tree",
  components: {
    Card: () => import("@/components/Cards/Card"),
    NoteCard: () => import("@/components/Cards/NoteCard"),
  },
  props: {
    items: Array,
  },
  computed: {},
  methods: {
    changeIcon(event) {
      if (event.srcElement.classList.contains("arrow-rotate"))
        event.srcElement.classList.remove("arrow-rotate");
      else event.srcElement.classList.add("arrow-rotate");
    },
  },
};
</script>

<style lang="scss">
.bi-arrow-up {
  animation: transform 1s ease;
  transform: rotate(0deg) !important;
}
.arrow-rotate {
  transform: rotate(180deg) !important;
}
</style>
